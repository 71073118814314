import Hls from 'hls.js';
import { useEffect, useRef } from 'react';

export interface IVideo {
  src: string;
  fallBackImage: string;
  placeHolderImage?: string;
  className: string;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  playsInline?: boolean;
}

export const VideoPlayer: React.FC<IVideo> = ({
  src,
  fallBackImage,
  placeHolderImage = '',
  className = '',
  autoPlay = true,
  loop = true,
  muted = true,
  playsInline = true,
}) => {
  const videoRef = useRef<any>(null);
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    if (video.canPlayType('application/vnd.apple.mpegurl')) {
      // This will run in safari, where HLS is supported natively
      video.src = src;
    } else if (Hls.isSupported()) {
      // This will run in all other modern browsers
      const hls = new Hls();
      hls.loadSource(src);
      hls.attachMedia(video);
    }
  }, [src, videoRef]);

  return (
    <div className={className}>
      <video
        id="video"
        controls={false}
        data-displaymaxtap
        autoPlay={autoPlay}
        ref={videoRef}
        loop={loop}
        muted={muted}
        playsInline={playsInline}
        className={`object-cover`}
        poster={placeHolderImage}
      >
        <img
          src={fallBackImage}
          title="Your browser does not support the <video> tag"
        />
      </video>
    </div>
  );
};
