import { useSelector } from '@plasmicapp/loader-nextjs';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import { useState } from 'react';

import type { CountdownSectionRecordModelType } from '@/models/api';

const CountDown = dynamic(() => import('../CountDown/CountDown'), {
  ssr: false,
});

type CountDownBeforeProps = {
  text: string;
  subText: string;
  date: string;
  setCountdownDone: (countdownDone: boolean) => void;
};

const CountDownBefore = ({
  text,
  subText,
  date,
  setCountdownDone,
}: CountDownBeforeProps) => (
  <div className="flex flex-col items-center justify-center md:flex-row md:items-center md:justify-between">
    <div className="mb-5 max-w-[330px] text-center leading-[26px] md:mr-[20px] md:mb-0 md:max-w-[475px] md:text-left lg:mr-[30px]">
      <p className="mb-2 max-w-[296px] font-soehneBold text-xl md:max-w-none md:text-3xl">
        {text}
      </p>
      <p className="mb-3 font-interMedium text-base leading-5">{subText}</p>
      <p className="font-soehneBold text-xl md:text-3xl">
        {dayjs(date).format('DD.MM.YYYY')}
      </p>
    </div>
    <div className="">
      <CountDown
        value={date}
        backgroundClass="black"
        setCountdownDone={setCountdownDone}
      />
    </div>
  </div>
);

type CountDownContainerProps = {
  className?: string;
  dataProviderName?: string;
};

const CountDownContainer = observer(
  ({ className, dataProviderName = 'landingV2' }: CountDownContainerProps) => {
    const [countdownDone, setCountdownDone] = useState(false);

    const dataProvider = useSelector(dataProviderName);
    const sections = dataProvider?.sections;

    const CountdownData = sections.find(
      (item: CountdownSectionRecordModelType) =>
        // eslint-disable-next-line
				item.__typename === 'CountdownSectionRecord',
    );

    if (!CountdownData) {
      return null;
    }
    const { countdownText, countdownSubtext, countdownEndDate } = CountdownData;
    const isCountdownOver = dayjs().isAfter(countdownEndDate);

    if (isCountdownOver) {
      return null;
    }

    return !countdownDone ? (
      <div
        className={`${className} w-full max-w-[948px] rounded-2xl bg-black p-6 text-white md:flex-row md:rounded-full md:py-8 md:px-12 lg:px-16`}
      >
        <CountDownBefore
          text={countdownText || ''}
          subText={countdownSubtext || ''}
          setCountdownDone={setCountdownDone}
          date={countdownEndDate}
        />
      </div>
    ) : null;
  }
);

export default CountDownContainer;
