import { DataProvider, usePlasmicQueryData } from '@plasmicapp/loader-nextjs';

import { LANDING_PAGE_V2_SELECTOR } from '@/graphql/selectors/landingPage';
import { useStore } from '@/models/root-store';
import { getLandingPageVariation } from '@/utils/api-helpers';
import { formatPriceFields, objectParser } from '@/utils/track/format-helpers';

interface Props {
  children: React.ReactNode;
  className?: string;
  slug: string;
  content: string;
  campaign: string;
}

export const DyanmicLandingPageDataProvider: React.FC<Props> = ({
  children,
  className,
  content = 'default',
  campaign,
  slug,
}) => {
  const store = useStore();
  const { data: landingPageContent } = usePlasmicQueryData(
    `dynamicCampaign/${campaign}/${slug}/${content}`,
    async () => {
      return getLandingPageVariation(store, campaign, slug, content);
    }
  );
  const landingPageId = landingPageContent?.landingPage?.id;
  const { data: landingPageData } = usePlasmicQueryData(
    `/dynamicLanding/${landingPageId}`,
    async () => {
      if (!landingPageId) {
        return null;
      }
      const { landingPageV2Cms } = await store.api.queryLandingPageV2Cms(
        {
          filter: { id: { eq: landingPageId } },
          imgixParams: {
            auto: 'format',
          },
        },
        LANDING_PAGE_V2_SELECTOR
      );
      return landingPageV2Cms;
    }
  );

  if (!landingPageData) {
    return null;
  }
  const clonedData = objectParser(landingPageData);
  formatPriceFields(clonedData);
  return (
    <div className={className}>
      <DataProvider name="dynamicLanding" data={clonedData}>
        {children}
      </DataProvider>
    </div>
  );
};
