import { observer } from 'mobx-react-lite';
import React from 'react';

import { trackPromotionClicked } from '@/utils/track/tracker.helper';

type ToggleProps = {
  creative: string;
  visibility: boolean;
  className?: string;
  onChangeVisibility: (visibility: boolean) => void;
};

const Toggle: React.FC<ToggleProps> = observer(
  ({ creative, visibility, className, onChangeVisibility }: ToggleProps) => {
    const trackToggle = () => {
      trackPromotionClicked(creative, !visibility ? 'on' : 'off');
    };

    return (
      <label className={`${className} inline-flex cursor-pointer items-center`}>
        <input
          type="checkbox"
          value=""
          className="peer sr-only"
          onClick={() => {
            onChangeVisibility(visibility);
            trackToggle();
          }}
        />
        <div className="peer relative h-[14px] w-[34px] rounded-full bg-[#A8A8A8] after:absolute after:left-[-3px] after:top-[-3px] after:size-[20px] after:rounded-full after:border-2 after:border-white after:bg-white after:shadow-[0px_1px_1px_0px_#00000029,0px_3px_8px_0px_#00000026] after:transition-all after:content-[''] peer-checked:bg-[#222222] peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:after:bg-primary peer-focus:outline-none rtl:peer-checked:after:-translate-x-full dark:border-gray-600"></div>
      </label>
    );
  },
);

export default Toggle;
