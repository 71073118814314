import Script from 'next/script';
import React from 'react';

interface ProductReviewCarouselProps {
  identifier: string;
  className: string;
}

const ProductReviewCarousel: React.FC<ProductReviewCarouselProps> = ({
  identifier,
  className = '',
}) => {
  const ReviewSetting = `window.__productReviewSettings = {
    brandId: '24eebc6d-a76f-34f8-b3d6-5d19b74338d1'
  };`;
  const ReviewScript = `
    window.__productReviewCallbackQueue = window.__productReviewCallbackQueue || [];
    window.__productReviewCallbackQueue.push(function(ProductReview) {
      ProductReview.use('reviews-carousel', {
        "container": "#pr-reviews-carousel-widget",
        "identificationDetails": {
          "type": "single",
          "strategy": "from-internal-entry-id",
          "identifier": "${identifier}"
        }
      });
    });
  `;
  return (
    <div className={className}>
      <div id="pr-reviews-carousel-widget"></div>
      <Script
        id="ReviewSetting"
        dangerouslySetInnerHTML={{ __html: ReviewSetting }}
      ></Script>
      <Script
        src="https://cdn.productreview.com.au/assets/widgets/loader.js"
        async
      ></Script>
      <Script
        id="ReviewScript"
        async
        dangerouslySetInnerHTML={{ __html: ReviewScript }}
      ></Script>
    </div>
  );
};

export default ProductReviewCarousel;
