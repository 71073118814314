import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import React from 'react';

const LottiePlayer = dynamic(() => import('./LottiePlayer'), {
  ssr: false,
});

type LottieContainerProps = {
  path: string;
  className: string;
  width: string;
  height: string;
  id: string;
  play: boolean;
  loop: boolean;
};

const LottieContainer: React.FC<LottieContainerProps> = observer(
  ({
    className,
    path,
    id,
    width,
    height,
    play,
    loop,
  }: LottieContainerProps) => {
    return (
      <>
        <LottiePlayer
          path={path}
          className={className}
          width={width}
          height={height}
          id={id}
          play={play}
          loop={loop}
        />
      </>
    );
  },
);

export default LottieContainer;
