import 'react-responsive-carousel/lib/styles/carousel.min.css';

import React from 'react';
import { Carousel } from 'react-responsive-carousel';

type PlasmicSliderProps = {
  className?: string;
  children?: any;
  autoPlay?: boolean;
  infiniteLoop?: boolean;
  showArrows?: boolean;
  showIndicators?: boolean;
  showStatus?: boolean;
  showThumbs?: boolean;
  swipeable?: boolean;
  emulateTouch?: boolean;
};

const PlasmicSlider: React.FC<PlasmicSliderProps> = ({
  children,
  className,
  autoPlay = true,
  infiniteLoop = true,
  showArrows = false,
  showIndicators = false,
  showStatus = false,
  showThumbs = false,
  swipeable = true,
  emulateTouch = true,
}) => {
  return (
    <div className={`w-[700px] overflow-hidden rounded-32 ${className}`}>
      {children && (
        <Carousel
          autoPlay={autoPlay}
          infiniteLoop={infiniteLoop}
          showArrows={showArrows}
          showIndicators={showIndicators}
          showStatus={showStatus}
          showThumbs={showThumbs}
          swipeable={swipeable}
          emulateTouch={emulateTouch}
        >
          {React.Children.map(children, (child, index) => (
            <div key={index}>{child}</div>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default PlasmicSlider;
