import { observer } from 'mobx-react-lite';
import React from 'react';

import Toggle from './Toggle';
import styles from './ToggleContainer.module.css';

type ToggleContainerProps = {
  creative: string;
  visibility: boolean;
  className: string;
  onChangeVisibility: (visibility: boolean) => void;
};

const ToggleContainer: React.FC<ToggleContainerProps> = observer(
  ({
    creative,
    visibility,
    className,
    onChangeVisibility,
  }: ToggleContainerProps) => {
    const container = `${className} w-[156px] h-[32px] p-[6px] px-[10px] bg-[#FAE000] rounded-full flex items-center justify-end relative overflow-hidden`;

    return (
      <div className={`${styles.toggleContainer} ${container}`}>
        <Toggle
          creative={creative}
          visibility={visibility}
          onChangeVisibility={onChangeVisibility}
        />
      </div>
    );
  },
);

export default ToggleContainer;
