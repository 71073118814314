import _ from 'lodash';

import {
  CMS_PRODUCT_SELECTOR,
  PRODUCT_SELECTOR_NEW,
} from '@/graphql/selectors';
import type { ProductModelType } from '@/models/api';
import { ProductStatus } from '@/models/api';
import type { ProductStatusFilter } from '@/shared/constant';

const endpoint = process.env.NEXT_PUBLIC_GRAPHQL_V2_ENDPOINT || '';
const headers = {
  'Content-Type': 'application/json',
  'x-source': 'web',
};

const getProductsQuery = (selector: string) => {
  return `
query Products($where: ProductWhereInput, $skipVisibleCheck: Boolean, $limit: Int) {
  products(where: $where, skipVisibleCheck: $skipVisibleCheck, limit: $limit) {
    ${selector}
  }
}
`;
};

const getCmsProductsQuery = (selector: string) => {
  return `
query AllProductsCms($filter: ProductModelFilter, $imgixParams: ImgixParams, $first: IntType, $skip: IntType) {
  allProductsCms(filter: $filter, first: $first, skip: $skip) {
    ${selector}
  }
}
`;
};
export const fetchProducts = async (
  status: ProductStatusFilter = { _eq: ProductStatus.ACTIVE },
  selector: string = PRODUCT_SELECTOR_NEW,
  limit: number = 500,
) => {
  const body = {
    query: getProductsQuery(selector),
    variables: {
      where: {
        status,
      },
      skipVisibleCheck: true,
      limit,
    },
    operationName: 'Products',
  };
  const requestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(endpoint, requestOptions);
    if (!response.ok) {
      const errorMsg = 'Error response from Rome';
      console.error(errorMsg, response.status, response.body);
      throw new Error(errorMsg);
    }
    const jsonData = await response.json();
    if (!jsonData.data?.products) {
      const errorMsg = 'Error response from Rome - missing products';
      console.error(errorMsg, jsonData);
      throw new Error(errorMsg);
    }
    return jsonData.data.products;
  } catch (error) {
    throw new Error(
      `Something went wrong fetching products from Rome - ${
        error instanceof Error && error.message
      }`,
    );
  }
};

export const fetchProductsMetaCms = async (
  status: Record<string, any> = { eq: 'active' },
) => {
  const body = {
    query: `query _allProductsMetaCms($filter: ProductModelFilter) { _allProductsMetaCms(filter: $filter) { count } }`,
    variables: {
      filter: {
        productStatus: status,
      },
    },
    operationName: '_allProductsMetaCms',
  };
  const requestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(endpoint, requestOptions);
    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      data: { _allProductsMetaCms },
    } = await response.json();
    const { count } = _allProductsMetaCms;
    return count;
  } catch (error: any) {
    throw new Error('Error fetching products meta from cms');
  }
};

export const fetchProductsCms = async (
  count: number,
  selector: string = CMS_PRODUCT_SELECTOR,
  status: Record<string, any> = { eq: 'active' },
) => {
  const MAX_PRODUCTS = 100;
  const loops = Math.ceil(count / MAX_PRODUCTS);

  try {
    const promises: any = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < loops; i++) {
      const skip = i * MAX_PRODUCTS;

      const body = {
        query: getCmsProductsQuery(selector),
        variables: {
          filter: {
            productStatus: status,
          },
          imgixParams: {
            auto: 'format',
          },
          first: MAX_PRODUCTS,
          skip,
        },
        operationName: 'AllProductsCms',
      };

      const requestOptions = {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
      };

      promises.push(
        fetch(endpoint, requestOptions)
          .then((response) => response.json())
          .then((data) => data.data.allProductsCms)
          .catch((error) => {
            throw new Error(
              `Error fetching products from cms - ${
                error instanceof Error && error.message
              }`,
            );
          }),
      );
    }

    const results = await Promise.all(promises);
    const allProductsCms = results.flat();

    return allProductsCms;
  } catch (error) {
    throw new Error(
      `Error fetching products from cms - ${
        error instanceof Error && error.message
      }`,
    );
  }
};

export const stitchData = (
  products: ProductModelType[],
  productsCms: ProductModelType[],
) => {
  const cloneProducts = JSON.parse(JSON.stringify(products));
  const clonedCmsProducts = JSON.parse(JSON.stringify(productsCms));
  const cmsSkuLookup = _.keyBy(clonedCmsProducts, 'sku');
  const result = cloneProducts.map((product: ProductModelType) => {
    return {
      ...product,
      cms: cmsSkuLookup[product.sku] ?? null,
    };
  });
  return result;
};
