import { useSelector } from '@plasmicapp/loader-nextjs';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import Image from 'next/legacy/image';
import type { FC } from 'react';
import React from 'react';

import { MacrosContainer } from '@/components/common/MacrosContainer/MacrosContainer';
import { PrimaryDisplayTag } from '@/components/common/PrimaryDisplayTag/PrimaryDisplayTag';
import type { ProductRecordModelType } from '@/models/api';

interface PlasmicProductCardProps {
  sku: string;
  variation?: 'standard' | 'highlighted';
  showTag?: boolean;
  dataProviderName?: string;
}
const ProductCardContainer: FC<PlasmicProductCardProps> = observer(
  ({
    sku,
    variation = 'standard',
    showTag,
    dataProviderName = 'productCard',
  }: PlasmicProductCardProps) => {
    const dataProvider = useSelector(dataProviderName);
    const products = _.keyBy(dataProvider, 'sku');
    const product = products[sku];
    if (!product) {
      return null;
    }
    const {
      cms,
      sku: productSku,
    }: { cms: ProductRecordModelType | null; sku: string } = product;
    const primaryDisplayTag = cms?.primaryDisplayTag;
    const productImage = cms ? cms.tileImage?.url : '';
    return (
      <div
        id={productSku}
        className={`relative max-h-[295px] max-w-[175px] overflow-hidden ${variation === 'highlighted' ? ' overflow-hidden border-2 border-green-500' : 'overflow-auto'} rounded-2xl  transition-colors`}
      >
        {/* Tags */}
        {primaryDisplayTag && showTag && (
          <PrimaryDisplayTag tag={primaryDisplayTag} />
        )}
        {productImage && (
          <div className="relative -mx-[5%] -mt-[5%] w-[110%] overflow-hidden">
            <Image
              className="w-full object-cover"
              width={140}
              height={130}
              src={productImage}
              alt={''}
              quality={100}
              layout="responsive"
            />
          </div>
        )}
        {/* Nutrition Info */}
        {!product.hasSubProducts && (
          <MacrosContainer
            nutritionalInfo={product.nutritionalInfo}
            size="small"
          />
        )}
        <div
          className={`rounded-b-2xl ${variation === 'highlighted' ? 'bg-paleGreen' : 'bg-off-grey'} w-full p-3 pt-2`}
        >
          <span className="mb-1 line-clamp-3 font-interMedium  text-xs  leading-[16px] text-black hover:cursor-pointer align-top ">
            {cms?.displayName}
          </span>

          {/* Price */}
          <div className="mb-1 flex justify-between md:mb-3">
            <div className="flex items-end py-1 font-interSemibold text-base text-black md:text-lg">
              <span className="leading-5">
                ${product?.grossPrice?.toFixed(2)}
              </span>
            </div>

            {/* Desktop Weight */}
            {!product.hasSubProducts && (
              <div className="flex items-center py-1 font-interMedium text-xs text-grey-dark">
                {product.nutritionalInfo.size}
                {product.nutritionalInfo.unit}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
);

export default ProductCardContainer;
