import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import React, { useEffect } from 'react';

import { SkeletonCard } from '@/components/skeleton/SkeletonCard/SkeletonCard';
import menuBuilderData from '@/mock-data/menu-builder.json';
import type { ProductModelType } from '@/models/api';
import { useStore } from '@/models/root-store';
import { LoadingState, Mode } from '@/utils/constants';

import MenuBuilderCard from './MenuBuilderCard';

interface PlasmicMenuBuilderCardProps {
  sku: string;
  className?: string;
}

const PlasmicMenuBuilderCard: FC<PlasmicMenuBuilderCardProps> = observer(
  ({ sku, className = '' }: PlasmicMenuBuilderCardProps) => {
    if (!sku) {
      return null;
    }
    const store = useStore();
    const {
      productStore: { productsLookup, productState, allProductsCount },
      cartStore,
    } = store;
    const product = productsLookup[sku];
    const { cartToUse } = cartStore;
    const { menuBuilderPlan } = cartToUse;
    const dummySwapFunction = (
      _product: ProductModelType,
      _x: number,
      _y: number,
      _location?: string,
    ) => {
      // Dummy function to prevent error. Swap mode not used
    };

    useEffect(() => {
      if (!cartToUse?.menuBuilderPlan) {
        cartToUse?.initOrderPlan(menuBuilderData);
      }
    }, [cartToUse?.menuBuilderPlan]);

    if (
      !product ||
      productState === LoadingState.LOADING ||
      !allProductsCount
    ) {
      return (
        <div className={className}>
          <SkeletonCard />
        </div>
      );
    }

    return (
      <div className={className}>
        <MenuBuilderCard
          key={sku}
          product={product}
          orderPlan={menuBuilderPlan}
          mode={Mode.EDIT_CART}
          planRoute=""
          handleSwap={dummySwapFunction}
          shoppableTile
        />
      </div>
    );
  },
);

export default PlasmicMenuBuilderCard;
