import React, { useRef } from 'react';

import CheckIfWeDeliverModal from '@/components/checkDelivery/CheckIfWeDeliverModal/CheckIfWeDeliverModal';
import Button from '@/components/common/Button/Button';

interface CheckDeliveryButtonProps {
  children: React.ReactNode;
  className?: string;
  theme?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'custom'
    | 'wlp'
    | 'calorie'
    | 'muscle'
    | 'tofu'
    | 'quaternary'
    | 'mealPlan';
}

export const CheckDeliveryButton: React.FC<CheckDeliveryButtonProps> = ({
  children,
  theme,
  className,
}) => {
  const checkIfDeliverRef = useRef<any | null>(null);

  return (
    <div className={className}>
      <Button
        theme={theme}
        onClick={() => checkIfDeliverRef.current?.showCheckIfWeDeliverModal()}
      >
        {children}
      </Button>
      <CheckIfWeDeliverModal ref={checkIfDeliverRef} />
    </div>
  );
};
