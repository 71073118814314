import { DataProvider, usePlasmicQueryData } from '@plasmicapp/loader-nextjs';

import { LANDING_PAGE_V2_SELECTOR } from '@/graphql/selectors/landingPage';
import { useStore } from '@/models/root-store';
import { formatPriceFields, objectParser } from '@/utils/track/format-helpers';

interface Props {
  children: React.ReactNode;
  className?: string;
  slug: string;
}

export const LandingPageV2DataProvider: React.FC<Props> = ({
  children,
  className,
  slug,
}) => {
  const store = useStore();
  const { data } = usePlasmicQueryData(`/landing/${slug}`, async () => {
    const { landingPageV2Cms } = await store.api.queryLandingPageV2Cms(
      {
        filter: { slug: { eq: slug } },
        imgixParams: {
          auto: 'format',
        },
      },
      LANDING_PAGE_V2_SELECTOR,
    );
    return landingPageV2Cms;
  });

  if (!data) {
    return null;
  }
  const clonedData = objectParser(data);
  formatPriceFields(clonedData);
  return (
    <div className={className}>
      <DataProvider name="landingV2" data={clonedData}>
        {children}
      </DataProvider>
    </div>
  );
};
