import dynamic from 'next/dynamic';
import { useState } from 'react';

import useWindowDimensions from '@/hooks/useWindowDimensions';

const Confetti = dynamic(() => import('react-confetti'), { ssr: false });

interface ConfettiCanvasProps {
  timer?: number;
  tweenDuration?: number;
  gravity?: number;
}

const ConfettiCanvas: React.FC<ConfettiCanvasProps> = ({
  timer = 15000,
  gravity = 0.1,
  tweenDuration = 1000,
}) => {
  const [isActive, setIsActive] = useState(true);
  const { width = 0, height = 0 } = useWindowDimensions();

  setTimeout(() => {
    setIsActive(false);
  }, timer);

  return (
    <Confetti
      width={width}
      height={height}
      recycle={isActive}
      gravity={gravity}
      tweenDuration={tweenDuration}
    />
  );
};

export default ConfettiCanvas;
