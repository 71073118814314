import { Widget } from '@typeform/embed-react';

import { trackFormSubmitted } from '@/utils/track/tracker.helper';

interface TypeformWidgetProps {
  id: string;
  className: string;
  formName: string;
}

const TypeformWidget = ({ id, className, formName }: TypeformWidgetProps) => {
  const handleSubmit = () => {
    trackFormSubmitted(formName);
  };

  return (
    <>
      <div>
        <Widget
          id={id}
          className={className}
          onSubmit={handleSubmit}
          hideFooter={true}
          hideHeaders={true}
          opacity={0}
          inlineOnMobile={true}
        />
      </div>
    </>
  );
};

export default TypeformWidget;
