/* eslint no-nested-ternary: 0 */
import { initPlasmicLoader } from '@plasmicapp/loader-nextjs';

import { CheckDeliveryButton } from '@/components/checkDelivery/CheckDeliveryButton/CheckDeliveryButton';
import Button from '@/components/common/Button/Button';
import ConfettiCanvas from '@/components/common/ConfettiCanvas/ConfettiCanvas';
import CountDownContainer from '@/components/common/CountDownContainer/CountDownContainer';
import DeliveryBannerContainer from '@/components/common/DeliveryBannerContainer/DeliveryBannerContainer';
import LinkText from '@/components/common/LinkText/LinkText';
import LottieContainer from '@/components/common/LottiePlayer/LottieContainer';
import PlanTileContainer from '@/components/common/PlanTileContainer/PlanTileContainer';
import PlasmicSlider from '@/components/common/PlasmicSlider/PlasmicSlider';
import ProductCategoryTileContainer from '@/components/common/ProductCategoryTileContainer/ProductCategoryTileContainer';
import ProductReviewCarousel from '@/components/common/ProductReviewCarousel/ProductReviewCarousel';
import Toggle from '@/components/common/Toggle/Toggle';
import ToggleContainer from '@/components/common/Toggle/ToggleContainer';
import TypeformWidget from '@/components/common/TyepformWidget/TypeformWidget';
import { VideoPlayer } from '@/components/common/VideoPlayer/VideoPlayer';
import PlasmicMenuBuilderCard from '@/components/menu/MenuBuilderCard/PlasmicMenuBuilderCard';
import ProductCardContainer from '@/components/menu/ProductCard/PlasmicProductCard';
import { DyanmicLandingPageDataProvider } from '@/components/plasmic/DynamicLandingPageDataProvider';
import { GeneralPageDataProvider } from '@/components/plasmic/GeneralPageDataProvider';
import { LandingPageDataProvider } from '@/components/plasmic/LandingPageDataProvider';
import { LandingPageV2DataProvider } from '@/components/plasmic/LandingPageV2DataProvider';
import { ProductCardDataProvider } from '@/components/plasmic/ProductCardDataProvider';

export const PLASMIC = initPlasmicLoader({
  projects: [
    {
      id: process.env.PLASMIC_ID || '',
      token: process.env.PLASMIC_TOKEN || '',
      version: process.env.NEXT_PUBLIC_PLASMIC_ENV,
    },
  ],
  preview: process.env.NEXT_PUBLIC_PLASMIC_ENV !== 'production',
});

PLASMIC.registerComponent(Button, {
  name: 'Button',
  props: {
    children: {
      type: 'string',
      displayName: 'Text',
    },
    theme: {
      type: 'choice',
      options: [
        'primary',
        'secondary',
        'tertiary',
        'custom',
        'wlp',
        'calorie',
        'muscle',
        'quinary',
        'senary',
        'septenary',
      ],
    },
    link: 'string',
    typeFormId: 'string',
  },
});

PLASMIC.registerComponent(CheckDeliveryButton, {
  name: 'CheckDeliveryButton',
  props: {
    children: {
      type: 'string',
      displayName: 'Text',
    },
    className: 'string',
    theme: {
      type: 'choice',
      options: [
        'primary',
        'secondary',
        'tertiary',
        'custom',
        'wlp',
        'calorie',
        'muscle',
      ],
    },
  },
});

PLASMIC.registerComponent(ProductCategoryTileContainer, {
  name: 'ProductCategoryTileContainer',
  props: {
    children: 'slot',
    dataProviderName: {
      type: 'string',
      displayName: 'Data Provider Name',
      defaultValue: 'general',
    },
  },
});

PLASMIC.registerComponent(PlanTileContainer, {
  name: 'PlanTileContainer',
  props: {
    children: 'slot',
    dataProviderName: {
      type: 'string',
      displayName: 'Data Provider Name',
      defaultValue: 'general',
    },
  },
});

PLASMIC.registerComponent(DeliveryBannerContainer, {
  name: 'DeliveryBannerContainer',
  props: {
    children: 'slot',
    dataProviderName: {
      type: 'string',
      displayName: 'Data Provider Name',
      defaultValue: 'landing',
    },
  },
});

PLASMIC.registerComponent(LandingPageDataProvider, {
  name: 'LandingPageDataProvider',
  providesData: true,
  props: {
    slug: 'string',
    children: 'slot',
  },
});

PLASMIC.registerComponent(LandingPageV2DataProvider, {
  name: 'LandingPageV2DataProvider',
  providesData: true,
  props: {
    slug: 'string',
    children: 'slot',
  },
});

PLASMIC.registerComponent(ProductCardDataProvider, {
  name: 'ProductCardDataProvider',
  providesData: true,
  props: {
    children: 'slot',
    className: 'string',
  },
});

PLASMIC.registerComponent(GeneralPageDataProvider, {
  name: 'GeneralPageDataProvider',
  providesData: true,
  props: {
    slug: 'string',
    children: 'slot',
  },
});

PLASMIC.registerComponent(DyanmicLandingPageDataProvider, {
  name: 'DyanmicLandingPageDataProvider',
  providesData: true,
  props: {
    slug: 'string',
    content: 'string',
    campaign: 'string',
    children: 'slot',
  },
});

PLASMIC.registerComponent(LinkText, {
  name: 'LinkText',
  props: {
    text: 'string',
    link: 'string',
    hasArrow: 'boolean',
  },
});

PLASMIC.registerComponent(VideoPlayer, {
  name: 'VideoPlayer',
  props: {
    src: 'string',
    fallBackImage: 'string',
    placeHolderImage: 'string',
    className: 'string',
    autoPlay: 'boolean',
    muted: 'boolean',
    loop: 'boolean',
    playsInline: 'boolean',
  },
});

PLASMIC.registerComponent(ConfettiCanvas, {
  name: 'ConfettiCanvas',
  props: {
    timer: 'number',
    gravity: 'number',
    tweenDuration: 'number',
  },
});

PLASMIC.registerComponent(PlasmicSlider, {
  name: 'PlasmicSlider',
  props: {
    className: 'string',
    children: 'slot',
    autoPlay: 'boolean',
    infiniteLoop: 'boolean',
    showArrows: 'boolean',
    showIndicators: 'boolean',
    showStatus: 'boolean',
    showThumbs: 'boolean',
    swipeable: 'boolean',
    emulateTouch: 'boolean',
  },
});

PLASMIC.registerComponent(ProductReviewCarousel, {
  name: 'ProductReviewCarousel',
  props: {
    identifier: 'string',
    className: 'string',
  },
});

PLASMIC.registerComponent(CountDownContainer, {
  name: 'CountdownTimer',
  props: {
    className: 'string',
  },
});

PLASMIC.registerComponent(ProductCardContainer, {
  name: 'ProductCard',
  props: {
    sku: 'string',
    variation: {
      type: 'choice',
      options: ['standard', 'highlighted'],
      defaultValue: 'standard',
    },
    showTag: 'boolean',
    dataProviderName: {
      type: 'string',
      displayName: 'Data Provider Name',
      defaultValue: 'productCard',
    },
  },
});

PLASMIC.registerComponent(PlasmicMenuBuilderCard, {
  name: 'MenuBuilderCard',
  props: {
    sku: 'string',
    className: 'string',
  },
});

PLASMIC.registerComponent(TypeformWidget, {
  name: 'TypeformWidget',
  props: {
    id: 'string',
    className: 'string',
    formName: 'string',
  },
});

PLASMIC.registerComponent(ToggleContainer, {
  name: 'ToggleContainer',
  props: {
    creative: 'string',
    visibility: 'boolean',
    className: 'string',
    onChangeVisibility: {
      type: 'eventHandler',
      argTypes: [{ name: 'visibility', type: 'boolean' }],
    },
  },
  states: {
    visible: {
      type: 'writable',
      variableType: 'boolean',
      valueProp: 'visibility',
      onChangeProp: 'onChangeVisibility',
    },
  },
});

PLASMIC.registerComponent(Toggle, {
  name: 'Toggle',
  props: {
    creative: 'string',
    visibility: 'boolean',
    className: 'string',
    onChangeVisibility: {
      type: 'eventHandler',
      argTypes: [{ name: 'visibility', type: 'boolean' }],
    },
  },
  states: {
    visible: {
      type: 'writable',
      variableType: 'boolean',
      valueProp: 'visibility',
      onChangeProp: 'onChangeVisibility',
    },
  },
});

PLASMIC.registerComponent(LottieContainer, {
  name: 'Lottie Container',
  props: {
    path: 'string',
    className: 'string',
    width: 'string',
    height: 'string',
    id: 'string',
    play: 'boolean',
    loop: 'boolean',
  },
});
