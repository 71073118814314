import { DataProvider, usePlasmicQueryData } from '@plasmicapp/loader-nextjs';

import {
  PRODUCT_CARD_CMS_SELECTOR,
  PRODUCT_CARD_SELECTOR,
} from '@/graphql/selectors';
import { ProductStatus } from '@/models/api';
import {
  fetchProducts,
  fetchProductsCms,
  fetchProductsMetaCms,
  stitchData,
} from '@/utils/api-helpers/product-helpers';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const ProductCardDataProvider = ({ children, className }: Props) => {
  const { data } = usePlasmicQueryData(`/products/`, async () => {
    const count = await fetchProductsMetaCms({
      in: [
        ProductStatus.ACTIVE.toLowerCase(),
        ProductStatus.DRAFT.toLowerCase(),
        ProductStatus.DISCONTINUED.toLowerCase(),
        ProductStatus.OUT_OF_STOCK.toLowerCase(),
      ],
    });
    const [products, productsCms] = await Promise.all([
      fetchProducts(
        {
          _in: [
            ProductStatus.ACTIVE,
            ProductStatus.DRAFT,
            ProductStatus.DISCONTINUED,
            ProductStatus.OUT_OF_STOCK,
          ],
        },
        PRODUCT_CARD_SELECTOR,
        2000,
      ),
      fetchProductsCms(count, PRODUCT_CARD_CMS_SELECTOR, {
        in: [
          ProductStatus.ACTIVE.toLowerCase(),
          ProductStatus.DRAFT.toLowerCase(),
          ProductStatus.DISCONTINUED.toLowerCase(),
          ProductStatus.OUT_OF_STOCK.toLowerCase(),
        ],
      }),
    ]);
    return stitchData(products, productsCms);
  });

  if (!data) {
    return null;
  }

  return (
    <div className={className}>
      <DataProvider name="productCard" data={data}>
        {children}
      </DataProvider>
    </div>
  );
};
